<template>
  <v-container fluid style="margin:0px">
    <v-card elevation="0" style="margin-top: 0px !important; margin-bottom: -30px !important; overflow: hidden;background-color:transparent" class="px-5 py-1">
      <v-row class="custom-gray-background fill-width d-flex" v-if="mostrarFiltro">
       
       
        <v-col cols="12" sm="6" md="4">
          <span>Empresa</span>
          <v-select v-model="selectedCompany" multiple  @input="handleEmpresaSelection" :items="filteredEmpresas"  label="Empresa" required dense solo-inverted></v-select>
        </v-col>
       <!-- <v-col cols="12" sm="6" md="4">
          <span>Tipo</span>

        <v-select v-model="selectedType" :items="tipos" label="Tipo" dense solo-inverted></v-select>

        </v-col>-->
   

      </v-row>

      <v-divider></v-divider>

      <v-card-actions class="justify-end">

        <v-btn @click="alternarVisibilidade" color="#4682B4" small class="mr-2" style="font-size:14px !important;font-weight:bold">
          {{ mostrarFiltro ? 'Ocultar Filtro' : 'Mostrar Filtro' }}
          <v-icon style="margin-left:8px" dark>{{ mostrarFiltro ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
        </v-btn>

       <v-btn @click="limparFiltro" v-if="mostrarFiltro" color="#4682B4" small outlined class="mr-2" style="font-size:14px !important;font-weight:bold">
          Limpar
        </v-btn>
        <v-btn @click="filtrar" v-if="mostrarFiltro" color="#4682B4" small style="font-size:14px !important;font-weight:bold">
          Filtrar <v-icon style="margin-left:8px" dark>mdi-filter</v-icon>
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-container>
</template>


  
  
  <script>

  export default {

    props: {

      //tipos: Array,
      calendar: String
    },

    created() {
  

    
      },


    data() {
    return {
     
      mostrarFiltro: true,
          empresa: 'Todas',
          selectedCompany: ["Todas"],
          //selectedType:  "",
          user: "",
          empresas: ["CEMITERIO PREVIDA", "MEMORIAL PARQUE MACEIÓ", "PARQUE DAS FLORES", "PARQUE DO AGRESTE", "PREVIDA", "A FLOR DO CAFÉ", "Todas"],
          //userRoles: ["ROLE_PARQUE", "ROLE_PREVIDA"], // Substitua isso pelos papéis reais do usuário
          //userRoles: [],
          //opcoes: ["Pago", "Em Aberto", "Todas"],
          //modos: ["Vencimento", "Pagamento"],
    };
    },


    computed: {

    

    filteredEmpresas() {
      // Filtra as empresas com base nos papéis do usuário
      return this.empresas.filter(empresa => this.hasPermission(empresa));
    },

   
    tiposPermitidos() {
        let tiposPermitidos = ["Todos"];

        if (this.selectedType === "Jazigo") {
            tiposPermitidos = ["Todos", "CONCESSÃO DE COLUMBÁRIO", "CONCESSÃO DE JAZIGOS", "CONCESSÃO DE OSSÁRIO", "VENDA DE COLUMBÁRIO", "VENDA DE JAZIGOS - IMEDIATO", "VENDA DE JAZIGOS - PREVENTIVO", "VENDA DE JAZIGOS - TRANSITÓRIO"];
        } else if (this.selectedType === "Planos") {
            tiposPermitidos = ["Todos", "COMPLETO", "FAMILIA", "INTEGRAL", "P23", "PARQUE TOTAL", "PF - AMPARO", "PROTEÇÃO", "PV - AMIGO", "PV - BRONZE", "PV - CREMAÇÃO COM CERIMÔNIA", "PV - DIAMANTE", "PV - EMPRESARIAL", "PV - EMPRESARIAL - EMPRESA", "PV - ESPECIAL", "PV - ESMERALDA", "PV - ESSENCIAL", "PV - IDEAL", "PV - MAIOR", "PV - MAIS", "PV - MASTER GOLD", "PV - MELHOR", "PV - PLANO PJ", "PV - PLUS", "PV - POLICIA MILITAR", "PV - PRATA", "PV - PREVIDA", "PV - PREVIPARQ DO AGRESTE", "PV - RUBI", "PV - SÃO MATHEUS"];
        } else if (this.selectedType === "Cremação") {
            tiposPermitidos = [ "Todos",  "CREMAÇÃO", "CREMAÇÃO COM CERIMÔNIA", "CREMAÇÃO SEM CERIMÔNIA", "URNAS CINERARIAS"];
        } else if (this.selectedType === "Todos" ||  this.selectedType ===  "Funerária" || this.selectedType ===  "Serviços Administrativos" || this.selectedType ===  "Serviços Cemiteriais") {
            tiposPermitidos = [ "Todos"];
        }

        return tiposPermitidos;
    }

  },
 

    methods:{

      
        alternarVisibilidade() {
        this.mostrarFiltro = !this.mostrarFiltro;
      },
      
        filtrar() {

          this.$emit("filtrar", {
            selectedCompany: this.selectedCompany,
            //selectedType: this.selectedType,
          });

        },

        handleEmpresaSelection() {
          if (this.selectedCompany.includes("Todas")) {
            // Se "Todas" estiver selecionada, desabilita as outras opções
            this.selectedCompany = ["Todas"];
          }
        },

        limparFiltro(){

            this.empresa =  'Todas',
            this.selectedCompany = ["Todas"]
           // this.selectedType = "Todos",
          
        },



        hasPermission(empresa) {

          this.user = JSON.parse(sessionStorage.getItem('user'));

          // Verifica se o usuário tem permissão para ver a empresa
          if (this.user.roles.includes("ROLE_ADMIN")) {
            // Se o usuário tiver a role ROLE_ADMIN, ele pode ver todas as empresas
            return true;
          } else if (empresa === "Todas") {
            // A empresa "Todas" está sempre disponível para todos os usuários
            return true;
          } else {

            
            const requiredRoles_ = empresa.split(',').map(role => role.trim().toUpperCase().replace(/\s/g, '_'));
            const requiredRoleString = 'ROLE_' + requiredRoles_;
          
            const hasRequiredRole = this.user.roles.includes(requiredRoleString);
            return hasRequiredRole;
          }
        },

    },



  };
  </script>
  <style>
  /* Certifique-se de que o arquivo CSS com a classe .custom-col esteja importado aqui */
  .custom-col-13 {
    flex-basis: 13%; /* 15% é equivalente a 1.5 colunas em uma grade de 10 colunas */
    max-width: 13%;
  }
  .custom-col-15 {
    flex-basis: 15%; /* 15% é equivalente a 1.5 colunas em uma grade de 10 colunas */
    max-width: 15%;
  }
  </style>
  