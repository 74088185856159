<template>
 
  <v-app style="background-color:#f8f9fb" >

  <v-dialog v-model="showFilter" persistent max-width="300">
    <v-card>
      <v-card-title>
       Mensagem
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        A Diferença entre as datas não pode ser superior a {{ 
          this.dias }} dias.
      </v-card-text>
      <v-card-actions class="d-flex justify-center"> <!-- Centraliza horizontalmente -->
        <v-btn color="primary" @click="showFilter = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

   
    <!-- Diálogo de progresso -->
    <v-dialog v-model="showProgress" persistent max-width="300">
    <v-card>
      <v-card-title>
        Baixando arquivo...
      </v-card-title>
      <v-card-text>
        <!-- Coloque aqui qualquer indicador de progresso que você deseja mostrar -->
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>


  <v-container >
    <v-col cols="12" >
      <filtro-component :opcoes="opcoes" :modos="modos" :tipos="tipos" :calendar="'mes'" :produtos="tipos_funerarios"
      @filtrar="filterData" :title_opcao="'Situação'"
        ></filtro-component>
    </v-col>
  </v-container>


  <v-container style="background-color:#eee"  
    id="contrato"
    fluid
    tag="section">
 
    <v-row>
    
    <v-col
    cols="12">

    <v-col cols="12" sm="6" md="4">
          <v-text-field v-model="documento"  label="Documento"></v-text-field>
          </v-col>


        <base-material-card
        icon="mdi-file-document-refresh-outline"
        title="Lançamentos e Baixas Manuais"
        color="green" style="margin-top:20px !important; padding-top:10px !important"
        class="px-5 py-0"
          > 

      

              <v-data-table
              :headers="documentos_header"
              :items="documentos"
              :items-per-page="50" 
              items-per-page-text="Linhas por página"
              class="elevation-0">
      

                        <template v-slot:item="row">
                          
                          <tr :class="{'gray-row': row.index % 2 !== 0}">
                            <td style="width: 15%;font-size:14px;">{{row.item.dataConsulta}} </td>

                            <td style="width: 10%;font-size:14px;">
                              {{
                                      parseFloat(row.item.valor).toLocaleString('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL'
                                      })
                              }}  
                            </td>
                            <td style="width: 10%;font-size:14px;">{{row.item.tipo}} </td>
                            <td style="width: 10%;font-size:14px;">{{row.item.periodo}} </td>
                            <td style="width: 10%;font-size:14px;">{{row.item.situacao}} </td>
                            <td style="width:10%;font-size:14px;">
                              <v-chip 
                              :color="getEmpresa(row.item.empresa)"
                              text-color="white">
                                  {{row.item.empresa}}                    
                                  
                              </v-chip>  
                            </td>
                          </tr>
                      </template>
              </v-data-table>
          <br/>
         </base-material-card>
          <br/>

       
    </v-col>
  </v-row>
  </v-container>

</v-app> 
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import Radar from '../../services/radar'
import { ContentLoader } from 'vue-content-loader'
import FiltroComponent from "./components/FiltroDuo.vue"; // Certifique-se de que o caminho esteja correto


  export default {
    name: 'Cobranca',
  components: {
    apexcharts: VueApexCharts,
    FiltroComponent,
    ContentLoader,

  },


    
  mounted() {
    this.selectedStartDate = this.getFirstDayOfMonth(),
    this.selectedEndDate =  this.formatDateBR(new Date())
    this.getHistorico();
  },

 
    data () {
      return {
       
        rules: {
            required: value => !!value || 'Obrigatório.',
          },

          empresa: 'Todas',
          search: "",
          selectedCompany: "Todas",
          selectedType: "Todos",
          selectedOpcao: "Todas",
          selectedOpcao2: "NEGATIVADO",
          documento: 0,
          selectMode: "Todos",
          produto: 'Todos',
          selectedStartDate : "",
          selectedEndDate : "",
          isLoading: false,
          opcoes: [],
          modos: ["Data"],
          showFilter: false,
          empresas: ["CEMITERIO PREVIDA", "MEMORIAL PARQUE MACEIÓ", "PARQUE DO AGRESTE", "PREVIDA", "A FLOR DO CAFÉ", "Todas"],
          tipos: ["Lançamento", "Baixa"],

          showProgress: false,
          currentGroup: null,
          isLoading : false,
          showProgress : false,
          dias : 1,
          tipos_funerarios: [
                "Todos",
              ],

        documentos: [],
        documentos_header: [
          { text: 'Data', value: 'data' },
          { text: 'Valor', value: 'data_acordo' },
          { text: 'Tipo', value: 'tipo' },
          { text: 'Periodo', value: 'periodo' },
          { text: 'Situação', value: 'situação' },
          { text: 'Empresa', value: 'empresa' },

        ],

      }
  },

    methods: {

      formatDateBR(date) {
                if (!date) return '';
                const parsedDate = new Date(date);
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const year = parsedDate.getFullYear();
                return `${day}/${month}/${year}`;
            },


       filterData(valores) {

          this.isLoading = true;
          this.selectedStartDate = valores.selectedStartDate;
          this.selectedEndDate = valores.selectedEndDate;
          this.selectedCompany = valores.selectedCompany.join(",");
          this.selectedType = valores.selectedType;

          
          this.dashboard();

      },

    getEmpresa (status) {
            switch (status) {
            case 'Parque': return 'orange darken-2'
            case 'Versan' : return 'blue lighten-1'
            case 'Memorial' : return 'green lighten-1'
            case 'Am' : return 'cyan lighten-1'
            case 'Previda' : return 'blue lighten-1'
            case 'A FLOR DO CAFÉ' : return 'purple lighten-1'

              default: 'success'
            }
      },


       getFirstDayOfMonth() {
        const dataAtual = new Date();
        const primeiroDiaDoMes = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1);
        return this.formatDateBR(primeiroDiaDoMes);
        },

        getHistorico(){
      

          Radar.historicoapp().then(response => {
                          if(response.status === 200){
                            this.documentos = response.data;
                          } 
                        }).catch(e => {    
                        }).finally(() => {
               });
        },

    getStatus (status) {
            switch (status) {
            case 'Ativo': return 'blue lighten-1'
            case 'Cancelado' : return 'red lighten-1'
             default: 'success'
            }
      },

      dashboard() {
 
              const formattedStartDate = converteDataParaFormatoISO(this.selectedStartDate);
              const formattedEndDate = converteDataParaFormatoISO(this.selectedEndDate);

              const diferencaEmDias = this.calcularDiferencaEntreDatas(formattedStartDate, formattedEndDate);

                if (diferencaEmDias > 31) {
                  this.isLoading = false;
                  this.showFilter = true;
                  this.showProgress = false;
                  this.dias = 31;
                  return 
                }

              if(this.selectedCompany === 'Todas') return

              
              if(this.selectedType == 'Lançamento'){

                Radar.lancamento(formattedStartDate, formattedEndDate, this.selectedCompany, this.documento).then(response => {
                                  if(response.status === 200){
                                    alert(response.data)
                                  } 

                                }).catch(e => {    
                                }).finally(() => {
                                  
                });
              }else if(this.selectedType == 'Baixa'){

                Radar.baixa(formattedStartDate, formattedEndDate, this.selectedCompany, this.documento).then(response => {
                                  if(response.status === 200){
                                    alert(response.data)
                                  } 

                                }).catch(e => {    
                                }).finally(() => {
                                  
                });
                
              } else alert('Escolha opção diferente'+this.selectedType)

             


              

      },

      calcularDiferencaEntreDatas(dataInicial, dataFinal) {
                const data1 = new Date(dataInicial);
                const data2 = new Date(dataFinal);

                // Verifique se as datas são válidas
                if (isNaN(data1) || isNaN(data2)) {
                    return NaN; // Retorna NaN se uma das datas for inválida
                }

                const diferencaEmMilissegundos = data2 - data1;
                const diferencaEmDias = diferencaEmMilissegundos / (1000 * 60 * 60 * 24);

                return diferencaEmDias;
      },

      


    },
  }

  function converteDataParaFormatoISO(dataNoFormatoBR) {
              const partes = dataNoFormatoBR.split('/');
              if (partes.length === 3) {
                const [dia, mes, ano] = partes;
                return `${ano}-${mes}-${dia}`;
              }
              return null; // Retorna null se a data estiver em um formato inválido
            }


</script>

<style scoped>
  .v-progress-circular {
    margin: 1rem;
  }

  .custom-gray-background {
    height: 80px;
  }

  
  .vuetify__expand-icon {
  display: none !important; /* Oculta os ícones de expandir */
}
  /* Defina uma classe para preencher a largura horizontalmente e centralizar verticalmente */
  .fill-width {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    display: flex;
  }
  
  /* Centralize horizontalmente */
  .justify-center {
    justify-content: center;
  }

  .card-loader {
    height: 100%; /* Define a altura do content-loader como 100% para preencher o v-card */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .v-data-table-header-mobile .v-data-table-header-group {
  display: none !important; /* Oculta os botões de expansão de grupo */
  }

  
  </style>
