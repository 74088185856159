<template>
  <v-app style="background-color:#f8f9fb" >


  <v-dialog v-model="showFilter" persistent max-width="300">
    <v-card>
      <v-card-title>
       Mensagem
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        A Diferença entre as datas não pode ser superior a 365 dias.
      </v-card-text>
      <v-card-actions class="d-flex justify-center"> <!-- Centraliza horizontalmente -->
        <v-btn color="primary" @click="showFilter = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="showFilterDownload" persistent max-width="300">
    <v-card>
      <v-card-title>
       Mensagem
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        Em Manutenção
      </v-card-text>
      <v-card-actions class="d-flex justify-center"> <!-- Centraliza horizontalmente -->
        <v-btn color="primary" @click="showFilterDownload = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


  <v-container style="background-color:#f8f9fb;" >
      <v-col cols="12">
        <filtro-component :opcoes="opcoes" :produtos="tipos_funerarios" :modos="modos"  :tipos="tipos" :calendar="'mes'"
        @filtrar="filterData" :title_opcao="'Opções'" 
          ></filtro-component>
      </v-col>
    </v-container>

    <v-progress-linear v-if="load"
          color="cyan"
          indeterminate
        ></v-progress-linear>

    <v-container
    id="financeiro"
    fluid
    tag="section">
      <v-row >
        <v-col  
          cols="4"
          sm="4"
          md="4">
        </v-col>
        <v-col  v-if="comparar == false && load == false"
          cols="4"
          sm="4"
          md="4">
            <v-btn @click="comparar = true"  block color="green"  style="font-size:14px !important;font-weight:bold">
                Comparar <v-icon style="margin-left:8px" dark>mdi-arrow-left-right</v-icon>
           </v-btn>
        </v-col>
        <v-col v-if="comparar "
          cols="4"
          sm="4"
          md="4">
          <span>Selecione as Lojas</span>

          <v-combobox
            v-model="empresasSelecionadas"
            :items="lojas_filtradas"
            multiple dense solo-inverted
            label="Filtrar por empresa"
          ></v-combobox>

          <v-btn @click="empresasSelecionadas = []" color="green" small outlined class="mr-2" style="font-size:14px !important;font-weight:bold">
          Limpar
        </v-btn>

        <v-btn @click="comparar = false" color="#B71C1C"  small style="font-size:14px !important;font-weight:bold">
          Fechar <v-icon style="margin-left:8px" dark>mdi-close</v-icon>
        </v-btn>

        </v-col>

       
      
      </v-row>
    
      <v-row>

        <!-- Iteração para gerar os cards -->
        <v-col 
          v-for="(loja, index) in lojas" 
          :key="index"
          cols="12"
          sm="6" v-if="(comparar && empresasSelecionadas.includes(loja.loja)) || !comparar"
          md="6">
          <base-material-stats-card-loja
            elevation="1"
            :color="getEmpresa(loja.empresa)"
            :icon="getModelo(loja.loja)"
            :subIcon="'mdi-office-building'"
            :title="loja.loja"
            :valuex="false"          
            :subTextAdd="'- Cartão de Crédito: '"
            :subTextAddV="formatarMoeda(loja.formasPagamento['Cartão de Crédito'] || 0)"
            :subTextAdd1="'- Cartão de Débito: '"
            :subTextAdd1V="formatarMoeda(loja.formasPagamento['Cartão de Débito'] || 0)"
            :subTextAdd2="'- Dinheiro: '"
            :subTextAdd2V="formatarMoeda(loja.formasPagamento['Dinheiro'] || 0)"
            :subTextAdd3="'- Pix: '"
            :subTextAdd3V="formatarMoeda(loja.formasPagamento['PIX'] || 0)"
            :subTextAdd4="'Total Recebido: '"
            :subTextAdd4V="formatarMoeda(
              Object.values(loja.formasPagamento || {}).reduce((acc, val) => {
                const numVal = Number(val);
                return acc + (isNaN(numVal) ? 0 : numVal); 
              }, 0)
            )"
            :subTextAdd5="'- 0 a 6 Meses '"
            :subTextAdd5V="(loja.cancelamento.seisMeses || 0)"  
            :subTextAdd6="'- 6 Meses a 1 Ano '"
            :subTextAdd6V="(loja.cancelamento.umAno || 0)"  
            :subTextAdd7="'- 1 a 3 Anos: '"
            :subTextAdd7V="(loja.cancelamento.tresAnos || 0)"  
            :subTextAdd8="'- Acima de 3 Anos: '"
            :subTextAdd8V="(loja.cancelamento.acimaDeTresAnos || 0)" 
            :subTextAdd9="'Total Cancelado: '"
            :subTextAdd9V="(
              loja.cancelamento.seisMeses + loja.cancelamento.umAno + loja.cancelamento.tresAnos + loja.cancelamento.acimaDeTresAnos
            )"
            :subTextAdd10="'Total Previsto: '"
            :subTextAdd10V="formatarMoeda(loja.previsao || 0)"
            :subTextAdd11="'- Acordos Criados: '"
            :subTextAdd11V="formatarMoeda( loja.acordo_criado || 0 )" 
            :subTextAdd12="'- Acordos Pagos: '"
            :subTextAdd12V="formatarMoeda( loja.acordo_pagos || 0 )" 
            :subTextAdd13="'Total de Sinistro: '"
            :subTextAdd13V="loja.falecido" 
          />
        </v-col>
      </v-row>


</v-container>



</v-app> 
</template>
<script>
  import VueApexCharts from "vue-apexcharts";
  import axios from 'axios'
  import FiltroComponent from "./components/FiltroGeralLoja.vue"; // Certifique-se de que o caminho esteja correto
  import Financeiro from '../../services/financeiro' 
  import Contrato from '../../services/contrato' 
  import { ContentLoader } from 'vue-content-loader'

  export default {
    name: 'Obitos',
    components: {
      apexcharts: VueApexCharts,
      FiltroComponent,
      ContentLoader,

    },

    created() {
      this.selectedStartDate = this.getFirstDayOfMonth(),
      this.selectedEndDate =  this.formatDateBR(new Date())

    },

    mounted() {
     this.dashboard();
    },

    data () {
      return {
          produto: 'Todos',
          lojas: [],
          load:false,
          lojas_filtradas: [],
          empresasSelecionadas: [],
          total: 0,
          comparar: false,
          empresa: 'Todas',
          selectedCompany: "Todas",
          selectedType: "Todos",
          selectedOpcao: "Todas",
          opcoes: ["Todas"],
          selectMode: ["Período"],
          selectedStartDate : "",
          selectedEndDate : "",
          isLoading: false,
          modos: ["Período"],
          campos: 'N',
          categoria: [],
          showFilter: false,
          showFilterDownload: false,
          empresas: ["CEMITERIO PREVIDA", "MEMORIAL PARQUE MACEIÓ", "PARQUE DO AGRESTE", "PREVIDA", "A FLOR DO CAFÉ", "Todas"],
          tipos: ["Todos"],
          tipos_funerarios: [
                "Todos",
              ],

         

           lista_nome_empresa:[],
      
      }
    },

   
    methods: {
        isDateValid(dateString) {
          // Verifica se a string é uma data válida
          const date = new Date(dateString);
          return !isNaN(date.getTime());
        },
        areDatesValid(dates) {
          // Verifica se todas as datas na matriz são válidas
          return dates.every(dateString => {
            const date = new Date(dateString);
            return !isNaN(date.getTime());
          });
        },

      filterData(valores) {
        
          this.isLoading = true;
          this.selectedType = valores.selectedType === "Jazigo" ? "Cemitérios" : valores.selectedType;
          this.selectedOpcao = valores.selectedOpcao;
          this.selectedCompany = valores.selectedCompany.join(",");
          this.selectMode = valores.selectMode;
          this.selectedStartDate = valores.selectedStartDate;
          this.selectedEndDate = valores.selectedEndDate;
          this.produto = valores.selectedProduto;
          this.dashboard();
      },
     
      getFirstDayOfMonth() {
        const dataAtual = new Date();
        const primeiroDiaDoMes = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1);
        return this.formatDateBR(primeiroDiaDoMes);
        },

        startAnimation(targetValue) {
            this.animationInterval = setInterval(() => {
              if (this.animatedValue < targetValue) {
                this.animatedValue += 1; // Atualiza o valor animado
              } else {
                clearInterval(this.animationInterval); // Interrompe a animação quando atingir o valor desejado
              }
            }, 10); // Intervalo em milissegundos entre cada atualização
          },


        formatDateBR(date) {
                if (!date) return '';
                const parsedDate = new Date(date);
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const year = parsedDate.getFullYear();
                return `${day}/${month}/${year}`;
            },

        formatDateBRAPI(date) {
                if (!date) return '';
                const parsedDate = new Date(this.formatDateBR(date));
                const year = parsedDate.getFullYear();
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                return `${year}-${month}-${day}`;
              },



      async dashboard() {

              this.load = true;
              this.comparar = false;
              this.empresasSelecionadas = [];
              const formattedStartDate = converteDataParaFormatoISO(this.selectedStartDate);
              const formattedEndDate = converteDataParaFormatoISO(this.selectedEndDate);
              this.lojas = [];
              this.lojas_filtradas = [];

                const diferencaEmDias = this.calcularDiferencaEntreDatas(formattedStartDate, formattedEndDate);

                      if (diferencaEmDias > 90 && diferencaEmDias < 1) {
                        this.isLoading = false;
                        this.showFilter = true;
                        this.showProgress = false;
                        return 
                      }

                        Financeiro.lojas_recebimento(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedOpcao)
                        .then(response => {
                          if (response.status === 200) {
                            this.lojas = response.data.map(loja => {
                              const formasPagamento = loja.dados.reduce((acc, item) => {
                                acc[item.forma_pag] = item.valor;
                                return acc;
                              }, {});
                              return {
                                ...loja,
                                formasPagamento
                              };
                            });
                          }
                        })
                        .catch(error => {
                          console.error("Erro ao buscar dados:", error);
                        }).finally(() => {
                          this.load = false;
                        });

                        Financeiro.lista_lojas(this.selectedCompany)
                        .then(response => {
                          if (response.status === 200) {
                            this.lojas_filtradas = response.data;
                          }
                        })
                        .catch(error => {
                        }).finally(() => {
                        });
                    

      },
   
    
      formatarMoeda(valor) {
        // Verifica se o valor é numérico
        if (isNaN(valor)) {
            return 'Valor inválido';
        }

        // Formata o valor como moeda brasileira (BRL)
        return valor.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        });
    },

    calcularPercentual(valorParcial, valorTotal) {
      if (valorTotal === 0) {
        return 0; // Evitar divisão por zero
      }
      return ((valorParcial / valorTotal) * 100).toFixed(2);
    },

    getEmpresa (status) {
            switch (status) {
            case 'PARQUE DAS FLORES': return 'orange darken-2'
            case 'CEMITERIO PREVIDA' : return 'cyan lighten-1'
            case 'MEMORIAL PARQUE MACEIÓ' : return 'green lighten-1'
            case 'PARQUE DO AGRESTE' : return 'orange darken-4'
            case 'PREVIDA' : return 'blue lighten-1'

              default: 'success'
            }
      },

      getModelo(valor) {
          if (valor.includes('- Online')) {
              return 'mdi-web';
          } else {
              return 'mdi-store';
          }
      },

      calcularDiferencaEntreDatas(dataInicial, dataFinal) {
                const data1 = new Date(dataInicial);
                const data2 = new Date(dataFinal);

                // Verifique se as datas são válidas
                if (isNaN(data1) || isNaN(data2)) {
                    return NaN; // Retorna NaN se uma das datas for inválida
                }

                const diferencaEmMilissegundos = data2 - data1;
                const diferencaEmDias = diferencaEmMilissegundos / (1000 * 60 * 60 * 24);

                return diferencaEmDias;
      },


        clear () {
          this.$refs.form.reset();
        },

        descobrirMeses() {
          const anoAtual = new Date().getFullYear(); // Obtém o ano atual
          const mesAtual = new Date().getMonth(); // Obtém o ano atual

          for (let mes = (mesAtual-4); mes <= mesAtual; mes++) {
            this.categoria.push(formatMonthName(mes));
          }

        },

        
    },
  }

   
  function formatMoeda(value) {
                      // Use o método toLocaleString com a opção style: 'currency' para formatar como moeda

                      if (value === undefined || value === null) {
                          return 'R$ 0,00'; // Ou você pode retornar 0 como número, se preferir.
                        }

                      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                      //return 0
                      //return value
                  }

  function getMonthName(monthNumber) {
    const monthNames = [
        "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
        "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
    ];

    if (monthNumber >= 1 && monthNumber <= 12) {
        return monthNames[monthNumber - 1];
    } else {
        return ""; // Retorna uma string vazia para números de mês inválidos
    }
}

  function parseDate(dateString) {
    const parts = dateString.split('/');
    // Lembre-se que os meses em JavaScript começam do zero
    const year = parseInt(parts[2], 10);
    const month = parseInt(parts[1], 10) - 1;
    const day = parseInt(parts[0], 10);
    return new Date(year, month, day);
  }

  function converteDataParaFormatoISO(dataNoFormatoBR) {
              const partes = dataNoFormatoBR.split('/');
              if (partes.length === 3) {
                const [dia, mes, ano] = partes;
                return `${ano}-${mes}-${dia}`;
              }
              return null; // Retorna null se a data estiver em um formato inválido
            }




</script>
