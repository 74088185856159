import { http } from './config'
import authHeader from './auth-header';

export default {


    

    historico: (dataI, dataF, nomeEmpresa) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa
        }
        return http.get('/radar-dash/historico', {
           params: params,
           headers: authHeader()   
        })
    },


    historicoapp: () => {
       
        return http.get('/radar-dash/consulta', {
           headers: authHeader()   
        })
    },


    importacao_periodo: (dataI, dataF, nomeEmpresa) => {
        
        const params= {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa
        }
        return http.get('/radar-dash/importacao-periodo', {
            params: params,
            headers: authHeader()
        })        
    },

    lancamento: (dataI, dataF, nomeEmpresa, documento) => {
        
        const params= {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'documento': documento
        }
        return http.get('/radar-dash/lancamento', {
            params: params,
            headers: authHeader()
        })        
    },

    baixa: (dataI, dataF, nomeEmpresa, documento) => {
        
        const params= {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'documento': documento
        }
        return http.get('/radar-dash/baixa', {
            params: params,
            headers: authHeader()
        })        
    },
}
