import { http } from './config'
import authHeader from './auth-header';

export default {


    total_inadimplencia_periodo_v2: (dataI, dataF, nomeEmpresa, tipo, filtro, dataI2, dataF2, opcao, produto) => {
        const converteParaNull = (valor) => {
            return valor === null ? 'NULL' : valor;
        };

        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'data-inicial-2': converteParaNull(dataI2),
            'data-final-2': converteParaNull(dataF2),
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
'produto': produto,
        }
        return http.get('/financeiro-dash/total-inadimplencia-periodo-v2/', {
            params: params,
            headers: authHeader()
        })        
    },
//+ "    AND ((:filtro = 'Vencimento' AND (e.dt_recebimento IS NULL OR e.dt_recebimento > :dataInicial)  ) OR (:filtro = 'Vencimento e Recebimento' AND (CAST(e.dt_recebimento AS DATE) BETWEEN :dataInicial2 AND :dataFinal2)) )\n"


    total_inadimplencia_periodo_aberto_v2: (dataI, dataF, nomeEmpresa, tipo, filtro, dataI2, dataF2, opcao, produto) => {
        const converteParaNull = (valor) => {
            return valor === null ? 'NULL' : valor;
        };

        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'data-inicial-2': converteParaNull(dataI2),
            'data-final-2': converteParaNull(dataF2),
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto,
        }
        return http.get('/financeiro-dash/total-inadimplencia-periodo-aberto-v2/', {
            params: params,
            headers: authHeader()
        })        
    },

    qtd_inadimplencia_periodo_aberto_v2: (dataI, dataF, nomeEmpresa, tipo, filtro, dataI2, dataF2, opcao, produto) => {
        const converteParaNull = (valor) => {
            return valor === null ? 'NULL' : valor;
        };

        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'data-inicial-2': converteParaNull(dataI2),
            'data-final-2': converteParaNull(dataF2),
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto,
        }
        return http.get('/financeiro-dash/qtd-inadimplencia-periodo-aberto-v2/', {
            params: params,
            headers: authHeader()
        })        
    },


    total_inadimplencia_periodo_aberto_acordo_v2: (dataI, dataF, nomeEmpresa, tipo, filtro, dataI2, dataF2, opcao, produto) => {
        const converteParaNull = (valor) => {
            return valor === null ? 'NULL' : valor;
        };

        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'data-inicial-2': converteParaNull(dataI2),
            'data-final-2': converteParaNull(dataF2),
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto,
        }
        return http.get('/financeiro-dash/total-inadimplencia-periodo-aberto-acordo-v2/', {
            params: params,
            headers: authHeader()
        })        
    },

    


    total_inadimplencia_periodo_pago_posterior_v2: (dataI, dataF, nomeEmpresa, tipo, filtro, dataI2, dataF2, opcao, produto) => {
        const converteParaNull = (valor) => {
            return valor === null ? 'NULL' : valor;
        };

        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'data-inicial-2': converteParaNull(dataI2),
            'data-final-2': converteParaNull(dataF2),
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
'produto': produto,
        }
        return http.get('/financeiro-dash/total-inadimplencia-periodo-pago-posterior-v2/', {
            params: params,
            headers: authHeader()
        })        
    },

    

    
    total_recebimento_periodo_v2: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
       
        const params =  {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
'produto': produto,
        }
       
        return http.get('/financeiro-dash/total-recebimento-periodo-v2/', {
            params: params,
            headers: authHeader() 
        })
    },

    total_esperado_periodo_v2: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
        const params = {
         'data-inicial': dataI,
         'data-final': dataF,
         'empresa': nomeEmpresa,
         'tipo': tipo,
         'filtro': filtro,
         'opcao': opcao,
'produto': produto,
     }
         return http.get('/financeiro-dash/total-esperado-periodo-v2/', {
             params: params,
             headers: authHeader() 
         })
     },


     total_recebimento_vencimento_v2: (dataI, dataF, nomeEmpresa, tipo, filtro, dataI2, dataF2, opcao, produto) => {
        const converteParaNull = (valor) => {
            return valor === null ? 'NULL' : valor;
        };

        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'data-inicial-2': converteParaNull(dataI2),
            'data-final-2': converteParaNull(dataF2),
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto,
        }

        return http.get('/financeiro-dash/total-recebimento-vencimento-v2/', {
            params: params,
            headers: authHeader() 
        })
    },

    
    lista_lojas: ( nomeEmpresa) => {
        const converteParaNull = (valor) => {
            return valor === null ? 'NULL' : valor;
        };

        const params = {
            'empresa': nomeEmpresa
        }

        return http.get('/financeiro-dash/lista-lojas/', {
            params: params,
            headers: authHeader() 
        })
    },

   
    lojas_recebimento: (dataI, dataF, nomeEmpresa, tipo) => {
        const converteParaNull = (valor) => {
            return valor === null ? 'NULL' : valor;
        };

        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo
        }

        return http.get('/financeiro-dash/lojas-recebimento/', {
            params: params,
            headers: authHeader() 
        })
    },

    total_recebido_periodo_vencimento_acordos_v2: (dataI, dataF, nomeEmpresa, tipo, filtro, dataI2, dataF2, opcao, produto) => {
        const converteParaNull = (valor) => {
            return valor === null ? 'NULL' : valor;
        };

        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'data-inicial-2': converteParaNull(dataI2),
            'data-final-2': converteParaNull(dataF2),
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
'produto': produto,
        }

        return http.get('/financeiro-dash/total-acordo-recebimento-vencimento-v2/', {
            params: params,
            headers: authHeader() 
        })
    },

     
    ticket_medio_plano_periodo_vencimento_v2: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
      
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
'produto': produto,
        }

        return http.get('/financeiro-dash/total-ticket-medio-plano-esperado-vencimento-v2/', {
            params: params,
            headers: authHeader() 
        })
    },

    ticket_medio_plano_periodo_vencimento_caixa_v2: (dataI, dataF, nomeEmpresa, tipo, filtro, dataI2, dataF2, opcao, produto) => {
        const converteParaNull = (valor) => {
            return valor === null ? 'NULL' : valor;
        };

        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'data-inicial-2': converteParaNull(dataI2),
            'data-final-2': converteParaNull(dataF2),
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
'produto': produto,
        }

        return http.get('/financeiro-dash/total-ticket-medio-plano-esperado-vencimento-caixa-v2/', {
            params: params,
            headers: authHeader() 
        })
    },


    ticket_medio_jazigo_periodo_vencimento_caixa_v2: (dataI, dataF, nomeEmpresa, tipo, filtro, dataI2, dataF2, opcao, produto) => {
        const converteParaNull = (valor) => {
            return valor === null ? 'NULL' : valor;
        };

        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'data-inicial-2': converteParaNull(dataI2),
            'data-final-2': converteParaNull(dataF2),
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
'produto': produto,
        }

        return http.get('/financeiro-dash/total-ticket-medio-jazigo-esperado-vencimento-caixa-v2/', {
            params: params,
            headers: authHeader() 
        })
    },
    total_recebido_periodo_vencimento_taxa_v2: (dataI, dataF, nomeEmpresa, tipo, filtro, dataI2, dataF2, opcao, produto) => {
        const converteParaNull = (valor) => {
            return valor === null ? 'NULL' : valor;
        };

        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'data-inicial-2': converteParaNull(dataI2),
            'data-final-2': converteParaNull(dataF2),
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
'produto': produto,
        }

        return http.get('/financeiro-dash/total-taxa-recebimento-vencimento-v2/', {
            params: params,
            headers: authHeader() 
        })
    },

    


    total_recebido_periodo_vencimento_taxa_caixa_v2: (dataI, dataF, nomeEmpresa, tipo, filtro, dataI2, dataF2, opcao, produto) => {
        const converteParaNull = (valor) => {
            return valor === null ? 'NULL' : valor;
        };

        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'data-inicial-2': converteParaNull(dataI2),
            'data-final-2': converteParaNull(dataF2),
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
'produto': produto,
        }

        return http.get('/financeiro-dash/total-taxa-recebimento-vencimento-caixa-v2/', {
            params: params,
            headers: authHeader() 
        })
    },

    total_recebido_periodo_vencimento_venda_v2: (dataI, dataF, nomeEmpresa, tipo, filtro, dataI2, dataF2, opcao, produto) => {
        const converteParaNull = (valor) => {
            return valor === null ? 'NULL' : valor;
        };

        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'data-inicial-2': converteParaNull(dataI2),
            'data-final-2': converteParaNull(dataF2),
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
'produto': produto,
        }

        return http.get('/financeiro-dash/total-venda-recebimento-vencimento-v2/', {
            params: params,
            headers: authHeader() 
        })
    },

    total_recebido_periodo_vencimento_venda_caixa_v2: (dataI, dataF, nomeEmpresa, tipo, filtro, dataI2, dataF2, opcao, produto) => {
        const converteParaNull = (valor) => {
            return valor === null ? 'NULL' : valor;
        };

        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'data-inicial-2': converteParaNull(dataI2),
            'data-final-2': converteParaNull(dataF2),
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
'produto': produto,
        }

        return http.get('/financeiro-dash/total-venda-recebimento-vencimento-caixa-v2/', {
            params: params,
            headers: authHeader() 
        })
    },

    

    recebido_adiantado_vencimento_v2: (dataI, dataF, nomeEmpresa, tipo, filtro, dataI2, dataF2, opcao, produto) => {
        const converteParaNull = (valor) => {
            return valor === null ? 'NULL' : valor;
        };

        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'data-inicial-2': converteParaNull(dataI2),
            'data-final-2': converteParaNull(dataF2),
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
'produto': produto,
        }

        return http.get('/financeiro-dash/recebido-adiantado-vencimento-v2/', {
            params: params,
            headers: authHeader() 
        })
    },


    total_recebimento_periodo_posterior_v2: (dataI, dataF, nomeEmpresa, tipo, filtro, dataI2, dataF2, opcao, produto) => {
        const converteParaNull = (valor) => {
            return valor === null ? 'NULL' : valor;
        };

        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'data-inicial-2': converteParaNull(dataI2),
            'data-final-2': converteParaNull(dataF2),
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
'produto': produto,
        }
        return http.get('/financeiro-dash/total-recebimento-periodo-posterior-v2/', {
            params: params,
            headers: authHeader()
        })        
    },




    total_recebimento_periodo_anterior_v2:(dataI, dataF, nomeEmpresa, tipo, filtro, dataI2, dataF2, opcao, produto) => {
        const converteParaNull = (valor) => {
            return valor === null ? 'NULL' : valor;
        };

        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'data-inicial-2': converteParaNull(dataI2),
            'data-final-2': converteParaNull(dataF2),
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
'produto': produto,
     }
         return http.get('/financeiro-dash/total-recebimento-periodo-anterior-v2/', {
             params: params,
             headers: authHeader() 
         })
     },








    total_recebimento_metodo: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
'produto': produto,
        }
        return http.get('/financeiro-dash/total-recebimento-metodo/', {
           params: params,
           headers: authHeader()   
        })
    },


    
    
    total_acordos_novos: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
        
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
'produto': produto,
        }

        return http.get('/financeiro-dash/total-acordo-novos/', {
           params: params,
           headers: authHeader() 
              
        })
    },

    total_acordos_novos_cancelados: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
        
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
'produto': produto,
        }

        return http.get('/financeiro-dash/total-acordo-novos-cancelados/', {
           params: params,
           headers: authHeader() 
              
        })
    },

    total_acordos_novos_parcelado: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
        
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
'produto': produto,
        }

        return http.get('/financeiro-dash/total-acordo-novos-parcelado/', {
           params: params,
           headers: authHeader() 
              
        })
    },


    
    total_recebimento_caixa_forma: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
        
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
'produto': produto,
        }

        return http.get('/financeiro-dash/total-caixa-recebimento-forma/', {
           params: params,
           headers: authHeader() 
              
        })
    },

    
    
    
    total_perda_cancelamento: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
'produto': produto,
        }
        return http.get('/financeiro-dash/total-perda-cancelamento/', {
            params: params,
            headers: authHeader() 
        })
    },


    total_recebiment_periodo_mongeral: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
'produto': produto,
        }
        return http.get('/financeiro-dash/total-recebimento-periodo-mongeral/', {
            params: params,
            headers: authHeader() 
        })
    },

   


   


   
    
   
     
    total_recebimento_acordo_novos_parcelado: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
        
        const params= {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto,
        }
        return http.get('/financeiro-dash/total-recebimento-acordo-novos-parcelado/', {
            params: params,
            headers: authHeader()
        })        
    },

    total_recebimento_acordo_novos_unico: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
        
        const params= {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto,
        }
        return http.get('/financeiro-dash/total-recebimento-acordo-novos-unico/', {
            params: params,
            headers: authHeader()
        })        
    },

    total_recebimento_acordo_antigos: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
        
        const params= {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto,
        }
        return http.get('/financeiro-dash/total-recebimento-acordo-antigos/', {
            params: params,
            headers: authHeader()
        })        
    },

    total_recebimento_acordo: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
        
        const params= {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto,
        }
        return http.get('/financeiro-dash/total-recebimento-acordo/', {
            params: params,
            headers: authHeader()
        })        
    },


    qtd_recebimento_acordo: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
        
        const params= {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto,
        }
        return http.get('/financeiro-dash/qtd-recebimento-acordo/', {
            params: params,
            headers: authHeader()
        })        
    },



    

    total_semseguro_inadimplencia_periodo_v2: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
       
        const params =  {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
'produto': produto,
        }
       
        return http.get('/financeiro-dash/total-semseguro-inadimplencia-periodo-v2/', {
            params: params,
            headers: authHeader() 
        })
    }, 

    total_comseguro_inadimplencia_periodo_v2: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
       
        const params =  {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
'produto': produto,
        }
       
        return http.get('/financeiro-dash/total-comseguro-inadimplencia-periodo-v2/', {
            params: params,
            headers: authHeader() 
        })
    }, 

    total_recebimento_periodo_v2: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
       
        const params =  {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto,
        }
       
        return http.get('/financeiro-dash/total-recebimento-periodo-v2/', {
            params: params,
            headers: authHeader() 
        })
    }, 

    total_doc_recebimento_periodo_v2: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
       
        const params =  {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto,
        }
       
        return http.get('/financeiro-dash/total-doc-recebimento-periodo-v2/', {
            params: params,
            headers: authHeader() 
        })
    }, 

    total_contrato_recebimento_periodo_v2: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
       
        const params =  {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto,
        }
       
        return http.get('/financeiro-dash/total-contrato-recebimento-periodo-v2/', {
            params: params,
            headers: authHeader() 
        })
    }, 

    
    
    total_recebimento_periodo: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
       
        const params =  {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
'produto': produto,
        }
       
        return http.get('/financeiro-dash/total-recebimento-periodo/', {
            params: params,
            headers: authHeader() 
        })
    },
    
    

   

}
