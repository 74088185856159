import { http } from './config'
import authHeader from './auth-header';

export default {


    

  

    lista_boletos_ativos_sem_cpf_xls: () => {
      return http.get('/planilha-dash/lista-boletos-sem-cpf-xls/', {
        //params: params,
        responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
        headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui

      });
    
  },

  lista_boletos_ativos_sem_endereco_xls: () => {
    return http.get('/planilha-dash/lista-boletos-sem-endereco-xls/', {
      //params: params,
      responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
      headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui

    });
  
},


  

    total_boletos_sem_cpf: () => {
      return http.get('/auditoria-dash/total-boletos-sem-cpf/', {
         //params: params,
         headers: authHeader()   
      })  
    },

    total_boletos_sem_endereco: () => {
      return http.get('/auditoria-dash/total-boletos-sem-endereco/', {
         //params: params,
         headers: authHeader()   
      })  
    },

    total_cliente_falecidos: () => {
        return http.get('/auditoria-dash/total-cliente-falecidos/', {
           //params: params,
           headers: authHeader()   
        })  
    },

    total_cpf: () => {
      return http.get('/auditoria-dash/total-cpf-auditoria/', {
         //params: params,
         headers: authHeader()   
      })  
    },


    total_contrato_sem_boleto: () => {
      return http.get('/auditoria-dash/total-contrato-sem-boleto/', {
         //params: params,
         headers: authHeader()   
      })  
    },



    total_cliente_nascimento_auditoria: () => {
        return http.get('/auditoria-dash/total-cliente-nascimento-auditoria/', {
           //params: params,
           headers: authHeader()   
        })  
    },


      lista_cpf_xls: () => {

                return http.get('/planilha-dash/lista-cpf-auditoria-xls', {
                  //params: params,
                  responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
                  headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui
      
                });
      },

      lista_sem_boleto_xls: () => {

             
        return http.get('/planilha-dash/lista-sem-boleto-xls', {
          //params: params,
          responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
          headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui

        });

        
},





    lista_cliente_nascimento_erro_xls: () => {
                  return http.get('/planilha-dash/lista-cliente-nascimento-erro-xls', {
                    responseType: 'blob', 
                    headers: authHeader() 
                  });
    },


    lista_cliente_falecidos: (nomeEmpresa) => {
      const params= {
        'empresa': nomeEmpresa
      }
      return http.get('/auditoria-dash/lista-cliente-falecidos/', {
         params: params,
         headers: authHeader()   
      })  
  },
  

    total_ativo_parcela_vencer: (nomeEmpresa) => {
      const params= {
        'empresa': nomeEmpresa
      }
      return http.get('/auditoria-dash/total-ativos-parcelas-vencer/', {
         params: params,
         headers: authHeader()   
      })  
    },

    total_ticket_aberto: (nomeEmpresa) => {
      const params= {
        'empresa': nomeEmpresa
      }
      return http.get('/auditoria-dash/total-ticket-aberto/', {
         params: params,
         headers: authHeader()   
      })  
    },

    total_titular_falecido: (nomeEmpresa) => {
      const params= {
        'empresa': nomeEmpresa
      }

      return http.get('/auditoria-dash/total-titular-falecido/', {
         params: params,
         headers: authHeader()   
      })  
    },

    total_problemas_doc: (nomeEmpresa) => {
      const params= {
        'empresa': nomeEmpresa
      }

      return http.get('/auditoria-dash/total-problemas-doc/', {
         params: params,
         headers: authHeader()   
      })  
    },

    total_servicos_carencia: (nomeEmpresa) => {
      const params= {
        'empresa': nomeEmpresa
      }

      return http.get('/auditoria-dash/total-servicos-carencia/', {
         params: params,
         headers: authHeader()   
      })  
    },

    total_caixa_aberto: (nomeEmpresa) => {
      const params= {
        'empresa': nomeEmpresa
      }

      return http.get('/auditoria-dash/total-caixa-aberto/', {
         params: params,
         headers: authHeader()   
      })  
    },



    total_pago_sem_juros: (nomeEmpresa) => {
      const params= {
        'empresa': nomeEmpresa
      }

      return http.get('/auditoria-dash/total-pago-sem-juros/', {
         params: params,
         headers: authHeader()   
      })  
    },

    total_pagamento_menor: (nomeEmpresa) => {
      const params= {
        'empresa': nomeEmpresa
      }

      return http.get('/auditoria-dash/total-recebimento-menor/', {
         params: params,
         headers: authHeader()   
      })  
    },

    
    lista_titular_falecido_xls: (nomeEmpresa) => {
      const params= {
        'empresa': nomeEmpresa
      }

      return http.get('/planilha-dash/lista-titular-falecido-xls', {
        params: params,
        responseType: 'blob', 
        headers: authHeader() 
      });   
  },

    lista_contratos_pago_sem_juros: (nomeEmpresa) => {
      const params= {
        'empresa': nomeEmpresa
      }

        return http.get('/planilha-dash/lista-contratos-pago-sem-juros-xls', {
          params: params,
          responseType: 'blob', 
          headers: authHeader() 
        });   
    },


    lista_servico_carencia_xls: (nomeEmpresa) => {
      const params= {
        'empresa': nomeEmpresa
      }
      return http.get('/planilha-dash/lista-servico-carencia-xls', {
        params: params,
        responseType: 'blob', 
        headers: authHeader() 
      });   
    },

    lista_problemas_doc_xls: (nomeEmpresa) => {
      const params= {
        'empresa': nomeEmpresa
      }
      return http.get('/planilha-dash/lista-problemas-doc-xls', {
        params: params,
        responseType: 'blob', 
        headers: authHeader() 
      });   
    },

    lista_caixa_aberto_xls: (nomeEmpresa) => {
      const params= {
        'empresa': nomeEmpresa
      }
      return http.get('/planilha-dash/lista-caixa-aberto-xls', {
        params: params,
        responseType: 'blob', 
        headers: authHeader() 
      });   
    },

    lista_ticket_aberto_xls: (nomeEmpresa) => {
      const params= {
        'empresa': nomeEmpresa
      }
      return http.get('/planilha-dash/lista-ticket-aberto-xls', {
        params: params,
        responseType: 'blob', 
        headers: authHeader() 
      });   
    },
    

    lista_pagamento_menor_xls: (nomeEmpresa) => {
      const params= {
        'empresa': nomeEmpresa
      }
      return http.get('/planilha-dash/lista-pagamento-menor-xls', {
        params: params,
        responseType: 'blob', 
        headers: authHeader() 
      });   
    },


   

    lista_ativos_parcelas_vencer_xls: (nomeEmpresa) => {

      const params= {
        'empresa': nomeEmpresa
      }


      return http.get('/planilha-dash/lista-ativos-parcelas-vencer-xls', {
        params: params,
        responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
        headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui

      });            
},

    




   



    


    
    
    

   

}
